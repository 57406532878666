<template>
  <section id="news" class="news-section">
      <b-container>

        <b-row>
          <b-col>
            <h2 class="subheader">Latest News</h2>
            <!--<div class="d-none d-sm-none d-md-none d-lg-block">
              <button class="btn gy-fill" v-on:click="scrollOver('left')"><b-icon-chevron-double-left></b-icon-chevron-double-left> Scroll Left </button>  
              <button class="btn gy-fill" v-on:click="scrollOver('right')">Scroll Right <b-icon-chevron-double-right></b-icon-chevron-double-right></button>
            </div>
            <div class="d-sm-block d-md-block d-lg-none">
              <p class="scroll-desc">Swipe For More News <b-icon-chevron-double-right></b-icon-chevron-double-right></p>
            </div> -->
          </b-col>
        </b-row>
        
        <b-row id="justKeepScrolling" class="gy-scroll flex-row flex-nowrap mt-4 pb-4 pt-2">
          
          <b-col sm="12" md="6" v-for="set in sets" :key="set.$index">
            <b-col sm="12" md="11" v-for="subset in set" :key="subset.$index">
              <b-card v-on:click="openArticle(subset.link)" img-left class="mb-5">
                <img class="card-img-left" :src="require(`../../assets/images/${subset.img}`)" :alt="subset.alt" />
                <b-card-text class="text-center">
                  {{subset.title}}
                </b-card-text>
                <div class="card-footer text-right"><a class="gy-link">More ></a></div>
              </b-card>
            </b-col>
          </b-col>

        </b-row>

      </b-container>
    </section>
</template>

<script>
window.dataLayer = window.dataLayer || []
export default {
  name: 'News',
  data() {
      return {
        newsArticles: [
          // add new articles to the top of this list
          {title: "Autofleet Announces Definitive Agreement to be Acquired by Element Fleet Management", alt: 'Autofleet logo', img: 'portfolio/autofleet-logo-color.png', link: 'https://autofleet.io/resource/autofleet-announces-definitive-agreement-to-be-acquired-by-element-fleet-management'},
          {title: "Helm.ai Introduces WorldGen-1, a First of Its Kind Multi-sensor Generative AI Foundation Model for Autonomous Driving", alt: 'Helm.ai logo', img: 'portfolio/helm-ai-logo.png', link: 'https://www.businesswire.com/news/home/20240730706413/en/'},
          {title: "EV Charging Provider Revel Signs 19K SF Lease in Downtown L.A.", alt: 'Revel logo', img: 'portfolio/revel-logo-color.png', link: 'https://commercialobserver.com/2024/07/revel-ev-downtown-la-lease-19ksf/'},
          {title: "AmpUp and EVSE Partner with the Los Angeles Bureau of Street Lighting (LABSL) to Power Streetlight EV Charging Stations", alt: 'AmpUp logo', img: 'portfolio/ampup-logo-color.png', link: 'https://ampup.io/blog/ampup-and-evse-partner-with-the-los-angeles-bureau-of-street-lighting-labsl-to-power-streetlight-ev-charging-stations'},
          {title: "Zypp Electric raises $15 million from Japanese firm Eneos for India expansion", alt: 'Zypp logo', img: 'portfolio/zypp-logo.png', link: 'https://economictimes.indiatimes.com/tech/technology/zypp-electric-raises-15-million-from-japanese-firm-eneos-for-expansion-in-india-and-overseas/articleshow/110457379.cms'},
          {title: "CorrActions raises $2.7M to help avoid errors in human-machine interactions", alt: 'CorrActions logo', img: 'portfolio/corractions-logo.png', link: 'https://techcrunch.com/2021/05/27/corractions-raises-2-7m-to-help-avoid-errors-in-human-machine-interactions/'},
          {title: "Software startup Recurrent helps used EV buyers know how much life is left in a vehicle", alt: 'Recurrent logo', img: 'portfolio/recurrent-logo-color.png', link: 'https://www.cnbc.com/2024/03/25/this-startup-is-figuring-out-how-much-used-evs-are-worth-by-analyzing-their-batteries.html'},
          {title: "Ottometric Closes $4.9M Seed Funding Round for Automated Validation of ADAS Systems", alt: 'Ottometric Logo', img: 'portfolio/ottometric-logo.png', link: 'https://www.prnewswire.com/news-releases/ottometric-closes-4-9m-seed-funding-round-for-automated-validation-of-adas-systems-301745593.html'},
          {title: "Starship Technologies raises $90M as its sidewalk robots pass 6M deliveries", alt: 'Starship logo', img: 'portfolio/starship-logo.png', link: 'https://techcrunch.com/2024/02/05/starship-technologies-raises-90m-as-its-sidewalk-robots-pass-6m-deliveries/'},
          {title: "Gatik Autonomous Trucks Know The Road Via Goodyear's Smart Tires", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.forbes.com/sites/richardbishop1/2024/01/09/gatik-autonomous-trucks-know-the-road-via-goodyears-smart-tires/'},
          {title: "Announcing the Helium Foundation Membership Program", alt: 'Nova Labs/Helium logo', img: 'portfolio/nova-helium-logo-color.png', link: 'https://blog.helium.com/announcing-the-helium-foundation-membership-program-6b947415d2bd'},
          {title: "Formant is managing data so robotics companies don't have to", alt: 'Formant logo', img: 'portfolio/formant-logo.png', link: 'https://techcrunch.com/2023/10/11/formant-is-managing-data-so-robotics-companies-dont-have-to/'},
          {title: "Helm.ai Named in CB Insights AI 100 Most Promising Artificial Intelligence Startup of 2023", alt: 'Helm.ai logo', img: 'portfolio/helm-ai-logo.png', link: 'https://www.benzinga.com/pressreleases/23/06/n33062445/helm-ai-named-in-cb-insights-ai-100-most-promising-artificial-intelligence-startup-of-2023'},
          {title: "Spiffy Embarks on Mission to Be the Employer of Choice for Automotive Services Professionals", alt: 'Spiffy logo', img: 'portfolio/spiffy-logo-color.png', link: 'https://finance.yahoo.com/news/spiffy-embarks-mission-employer-choice-110000297.html'},
          {title: "Estonia's Bolt, Starship in food delivery robot deal", alt: 'Starship logo', img: 'portfolio/starship-logo.png', link: 'https://www.reuters.com/markets/deals/estonias-bolt-starship-food-delivery-robot-deal-2023-06-21/'},
          {title: "Zypp Electric to deploy two lakh vehicles in its fleet, invest $300 million for expansion", alt: 'Zypp logo', img: 'portfolio/zypp-logo.png', link: 'https://economictimes.indiatimes.com/tech/startups/zypp-electric-to-deploy-2-lakh-vehicles-in-its-fleet-invest-300-million-for-expansion/articleshow/100564114.cms'},
          {title: "Zypp Electric’s Annual Revenue Jumps 459% In Fy23 As It Gears Up For Expansion In Bengaluru", alt: 'Zypp logo', img: 'portfolio/zypp-logo.png', link: 'https://yourstory.com/2023/05/zypp-electric-annual-revenue-jumps-459-percent-fy23-bengaluru-expansion-plans'},
          {title: "Blink Charging’s Wholly Owned Subsidiary Blink Mobility Announces Acquisition of Envoy, Expanding its Position in the Growing EV Car-Sharing Industry", alt: 'Envoy logo', img: 'portfolio/envoy-logo.png', link: 'https://www.globenewswire.com/news-release/2023/04/25/2654025/0/en/Blink-Charging-s-Wholly-Owned-Subsidiary-Blink-Mobility-Announces-Acquisition-of-Envoy-Expanding-its-Position-in-the-Growing-EV-Car-Sharing-Industry.html'},
          {title: "Zomato, Zypp Electric partner to deploy one-lakh e-scooters for last-mile delivery by 2024", alt: 'Zypp Logo', img: 'portfolio/zypp-logo.png', link: 'https://www-thehindu-com.cdn.ampproject.org/c/s/www.thehindu.com/business/Industry/zypp-electric-zomato-join-hands-to-deploy-one-lakh-e-scooters-for-last-mile-delivery-by-2024/article66776489.ece/amp/'},
          {title: "Revel opens electric vehicle charging superhub open to public 24/7 in Brooklyn", alt: 'Revel logo', img: 'portfolio/revel-logo-color.png', link: 'https://www.cbsnews.com/newyork/news/revel-opens-electric-vehicle-charging-superhub-open-to-public-247-in-brooklyn/'},
          {title: "Starship Technologies achieves industry-first autonomy milestone", alt: 'Starship logo', img: 'portfolio/starship-logo.png', link: 'https://www.starship.xyz/press_releases/starship-technologies-achieves-industry-first-autonomy-milestone/'},
          {title: "Gatik’s Gautam Narang on the importance of knowing your customer", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://techcrunch.com/2023/03/07/gatiks-gautam-narang-on-the-importance-of-knowing-your-customer/'},
          {title: "Announcing Our $30 Million Series C Funding Round", alt: 'Spiffy logo', img: 'portfolio/spiffy-logo-color.png', link: 'https://blog.getspiffy.com/announcing-our-30-million-series-c-funding-round?utm_campaign=Boost_Post_Video+Views_Feb+15,+2023,+17:30:39&utm_source=linkedin&utm_medium=paid&hsa_acc=506029990&hsa_cam=633838523&hsa_grp=220634323&hsa_ad=231671893&hsa_net=linkedin&hsa_ver=3'},
          {title: "Ottometric Closes $4.9M Seed Funding Round for Automated Validation of ADAS Systems", alt: 'Ottometric Logo', img: 'portfolio/ottometric-logo.png', link: 'https://finance.yahoo.com/news/ottometric-closes-4-9m-seed-130000794.html'},
          {title: "EV startup Zypp Electric nabs $25M to hit 30 Indian cities by 2025", alt: 'Zypp Logo', img: 'portfolio/zypp-logo.png', link: 'https://techcrunch.com/2023/02/08/zypp-electric-funding-gogoro/'},
          {title: "PAVEcast: A conversation about autonomous vehicles", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.buzzsprout.com/1904760/12123571'},
          {title: "Mimiq chooses Helium Network to launch claimed ‘world’s thinnest tracker’ at CES 2023", alt: 'Nova Labs/Helium logo', img: 'portfolio/nova-helium-logo-color.png', link: 'https://www.iot-now.com/2023/01/04/126606-mimiq-chooses-helium-network-to-launch-claimed-worlds-thinnest-tracker-at-ces-2023/'},
          {title: "Autofleet Launches Self-Serve Electrification Simulator", alt: 'Autofleet logo', img: 'portfolio/autofleet-logo-color.png', link: 'https://www.aftermarketnews.com/autofleet-launches-first-self-serve-electrification-simulator/'},
          {title: "Meet Gatik, Which May Be The Leader In Self-Driving Trucks By Attacking The Middle Mile", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.forbes.com/sites/bradtempleton/2022/12/23/meet-gatik-which-may-be-the-leader-in-self-driving-trucks-by-attacking-the-middle-mile/?sh=80179987802b'},
          {title: "Goodyear, Gatik say tire tech is key to bringing AVs to winter climates", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://techcrunch.com/2023/01/04/goodyear-gatik-say-tire-tech-is-key-to-bringing-avs-to-winter-climates/'},
          {title: "Revel to open five additional public EV fast charging stations in NYC", alt: 'Revel logo', img: 'portfolio/revel-logo-color.png', link: 'https://electrek.co/2023/01/09/revel-to-open-five-additional-public-ev-fast-charging-stations-in-nyc/'},
          {title: "Spiffy Announces 90%+ y/y Growth and Addition of Brad Schomber as New CFO", alt: 'Spiffy logo', img: 'portfolio/spiffy-logo-color.png', link: 'https://www.accesswire.com/734878/Spiffy-Announces-90-yy-Growth-and-Addition-of-Brad-Schomber-as-New-CFO?__hstc=181406584.bd79a374acfee30b6f35abf05dfdabf2.1675108992271.1675108992271.1675108992271.1&__hssc=181406584.1.1675108992272&__hsfp=3483462447&hsCtaTracking=ca9b75a3-b25f-4a32-99b1-e84e2c8e7c2b%7C06299b18-ccc6-4f78-b514-25a90c32e1ec'},
          {title: "Recurrent Range Score: It’s Like “CarFax” for EV Batteries", alt: 'Recurrent logo', img: 'portfolio/recurrent-logo-color.png', link: 'https://www.motortrend.com/features/recurrent-range-score-artificial-intelligence-predicts-used-electric-car-battery-life/'},
          {title: "Q&A KOBI EISENBERG: Autofleet’s CEO talks taxi industry tech, cancellations, dispatch and more", alt: 'Autofleet logo', img: 'portfolio/autofleet-logo-color.png', link: 'https://www.taxi-point.co.uk/post/q-a-kobi-eisenberg-autofleet-s-ceo-talks-taxi-industry-tech-cancellations-dispatch-and-more'},
          {title: "Automotive News Video: Experian, Recurrent partner to help ease range anxiety", alt: 'Recurrent logo', img: 'portfolio/recurrent-logo-color.png', link: 'https://www.autonews.com/video/automotive-news-video/automotive-news-video-experian-recurrent-partner-help-ease-range'},
          {title: "Nexteer & Tactile Mobility Honored with CES 2023 Innovation Award for Road Surface Detection & Early Intuitive Warning Software", alt: 'Tactile Mobility logo', img: 'portfolio/tactile-mobility-logo.png', link: 'https://www.koreaherald.com/view.php?ud=20221123000698'},
          {title: "Goodyear Ventures Invests in Helm.ai", alt: 'Goodyear Ventures logo', img: 'portfolio/goodyear-ventures-logo.png', link: 'https://helm-ai.medium.com/goodyear-ventures-invests-in-helm-ai-1947f6dfb832'},
          {title: "Revel is going to build more EV fast charging Superhubs in the US", alt: 'Revel logo', img: 'portfolio/revel-logo-color.png', link: 'https://electrek.co/2022/11/15/revel-ev-fast-charging-superhubs-us/'},
          {title: "Gatik named a TIME Best Invention of 2022 for World-First Fully Driverless Middle Mile Trucking Solution", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.businesswire.com/news/home/20221110005799/en/Gatik-named-a-TIME-Best-Invention-of-2022-for-World-First-Fully-Driverless-Middle-Mile-Trucking-Solution'},
          {title: "Goodyear, Drive TLV Join Forces To Explore Advanced Mobility Solutions With Israeli Startups", alt: 'Goodyear Ventures logo', img: 'portfolio/goodyear-ventures-logo.png', link: 'https://corporate.goodyear.com/us/en/media/news/goodyear-drive-tlv-join-forces-to-explore-advanced-mobility-solutions-with-israeli-startups.html'},
          {title: "Gatik Named to FreightWaves’ 2023 FreightTech 25 List", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.businesswire.com/news/home/20221103006388/en/Gatik-Named-to-FreightWaves%E2%80%99-2023-FreightTech-25-List'},
          {title: "DRIVING DISRUPTION: How Get Spiffy continues to bring a sustainable approach to premium auto services", alt: 'Spiffy logo', img: 'portfolio/spiffy-logo-color.png', link: 'https://www.bizjournals.com/triangle/news/2022/10/21/how-get-spiffy-continues-to-be-sustainable.html?b=1666319980%5E22152402'},
          {title: "AAA club partners with Recurrent to monitor EV batteries", alt: 'Recurrent logo', img: 'portfolio/recurrent-logo-color.png', link: 'https://www.autoremarketing.com/technology/aaa-club-partners-recurrent-monitor-ev-batteries'},
          {title: "Gatik Goes Driverless In Canada For Grocery Giant Loblaws", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.forbes.com/sites/edgarsten/2022/10/05/gatik-goes-driverless-in-canada-for-grocery-giant-loblaws/?sh=1ea8eaf55f51'},
          {title: "Nova Labs Inks Agreement With T-Mobile to Cover 5G Dead Spots in Helium Network", alt: 'Nova Labs/Helium logo', img: 'portfolio/nova-helium-logo-color.png', link: 'https://www.coindesk.com/markets/2022/09/20/nova-labs-says-t-mobile-to-cover-5g-dead-spots-in-helium-network/'},
          {title: "Used Electric Vehicle Values Become More Precise with Recurrent and Black Book Collaboration", alt: 'Recurrent logo', img: 'portfolio/recurrent-logo-color.png', link: 'https://apnews.com/press-release/globe-newswire/technology-electric-vehicles-climate-and-environment-a1f837ed0c924a795ac69fa15f6c0399'},
          {title: "Autonomous delivery company Gatik wins new pilot program with Pitney Bowes in Dallas", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.cnbc.com/2022/08/31/autonomous-delivery-company-gatik-wins-new-pilot-program-with-pitney-bowes-in-dallas.html#:~:text=Autonomous%20delivery%20company%20Gatik%20wins%20new%20pilot%20program%20with%20Pitney%20Bowes%20in%20Dallas,-Published%20Wed%2C%20Aug&text=Logistics%20company%20Pitney%20Bowes%20will,run%20packages%20between%20distribution%20centers'},
          {title: "Cummins to integrate Gatik’s autonomous driving tech into advanced powertrain", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.freightwaves.com/news/cummins-to-use-gatik-autonomous-tech-in-isuzu-trucks'},
          {title: "Revel, Fermata Energy, NineDot Energy launch first V2G system on NYC’s grid", alt: 'Revel logo', img: 'portfolio/revel-logo-color.png', link: 'https://www.greencarcongress.com/2022/08/20220819-nycv2g.html'},
          {title: "Helium Developer Nova Labs Buys FreedomFi to Accelerate Push Into Mobile Service", alt: 'Nova Labs/Helium logo', img: 'portfolio/nova-helium-logo-color.png', link: 'https://www.coindesk.com/markets/2022/08/18/helium-developer-nova-labs-buys-freedomfi-to-accelerate-push-into-mobile-service/'},
          {title: "Are we falling in love with robots?", alt: 'Starship logo', img: 'portfolio/starship-logo.png', link: 'https://www-bbc-co-uk.cdn.ampproject.org/c/s/www.bbc.co.uk/news/business-62007675.amp'},
          {title: "EvoCharge® and AmpUp Announce Partnership for Intuitive EV Charging Solutions", alt: 'AmpUp logo', img: 'portfolio/ampup-logo-color.png', link: 'https://evocharge.com/resources/evocharge-and-ampup-announce-partnership-for-intuitive-ev-charging-solutions/'},
          {title: "Goodyear Ventures' Erin Spring - A 125-year-old company looks to the future of mobility", alt: 'Goodyear Ventures logo', img: 'portfolio/goodyear-ventures-logo.png', link: 'https://podcasts.apple.com/us/podcast/goodyear-ventures-erin-spring/id1448400925?i=1000571601749'},
          {title: "Gatik’s autonomous fleet is proving driverless trucks can make roads safer", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.freightwaves.com/news/gatiks-autonomous-fleet-is-proving-driverless-trucks-can-make-roads-safer'},
          {title: "How tomorrow’s car could ‘feel’ the road", alt: 'Tactile Mobility logo', img: 'portfolio/tactile-mobility-logo.png', link: 'https://www.just-auto.com/interview/how-tomorrows-car-could-feel-the-road/'},
          {title: "Leaders in VC on Funding Emerging Tech", alt: 'Goodyear Ventures logo', img: 'portfolio/goodyear-ventures-logo.png', link: 'https://www.bloomberg.com/news/videos/2022-06-08/leaders-in-vc-on-funding-emerging-tech'},
          {title: "Gatik’s self-driving trucks to haul Georgia-Pacific goods to Sam’s Club stores", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://techcrunch.com/2022/06/07/gatik-georgia-pacific-kbx-sams-club-partnership/'},
          {title: "Tactile Mobility Launches Comprehensive Virtual Tire Health Monitoring", alt: 'Tactile Mobility logo', img: 'portfolio/tactile-mobility-logo.png', link: 'https://www.prnewswire.com/il/news-releases/tactile-mobility-launches-comprehensive-virtual-tire-health-monitoring-301557700.html'},
          {title: "Gatik And Walmart Notch A Big Win In Kansas With New Law Enabling Driverless Vehicle Deployment", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.forbes.com/sites/richardbishop1/2022/05/19/gatik-and-walmart-notch-a-big-win-in-kansas-with-new-law-enabling-driverless-vehicle-deployment/?sh=2d0d08ae2482'},
          {title: "Revel founder Frank Reig a year later on driving EV adoption in big cities", alt: 'Revel logo', img: 'portfolio/revel-logo-color.png', link: 'https://techcrunch.com/2022/05/13/revel-founder-frank-reig-one-year-later-on-driving-ev-adoption-in-big-cities/'},
          {title: "Revel aims to charge up NYC’s EV infrastructure", alt: 'Revel logo', img: 'portfolio/revel-logo-color.png', link: 'https://www.greenbiz.com/article/revel-aims-charge-nycs-ev-infrastructure'},
          {title: "It’s Not Just Tesla. 8 Other Stocks to Play the Future of Transportation.", alt: 'Goodyear Ventures logo', img: 'portfolio/goodyear-ventures-logo.png', link: 'https://www.marketwatch.com/articles/stocks-invest-future-transportation-tesla-uber-51651871098?tesla=y'},
          {title: "Goodyear Ventures’ Erin Spring on investing in mobility startups (Episode 146)", alt: 'Goodyear Ventures logo', img: 'portfolio/goodyear-ventures-logo.png', link: 'https://www.autonews.com/shift-podcast-about-mobility/goodyear-ventures-erin-spring-investing-mobility-startups-episode-146'},
          {title: "Helium Crypto Wireless Network Founders Raise $200M, Rebrand to Nova Labs", alt: 'Nova Labs/Helium logo', img: 'portfolio/nova-helium-logo-color.png', link: 'https://decrypt.co/96386/helium-crypto-wireless-network-founders-raise-200m-rebrand-to-nova-labs'},
          {title: "Goodyear Ventures Expands Portfolio with Nova Labs Investment to Advance Connected Mobility On The Helium Network", alt: 'Goodyear Ventures logo', img: 'portfolio/goodyear-ventures-logo.png', link: 'https://corporate.goodyear.com/us/en/media/news/goodyear-ventures-expands-portfolio-with-nova-labs-investment-to-advance-connected-mobility-on-the-helium-network.html'},
          {title: "Recurrent’s Next Growth Phase with New Partners and Funding", alt: 'Recurrent logo', img: 'portfolio/recurrent-logo-color.png', link: 'https://www.recurrentauto.com/news/next-growth-phase-new-partners-funding'},
          {title: "Goodyear Ventures seeks more than financial return", alt: 'Goodyear Ventures logo', img: 'portfolio/goodyear-ventures-logo.png', link: 'https://www.crainscleveland.com/finance/goodyear-ventures-seeks-more-financial-return-its-100-million'},
          {title: "Starship Technologies raises another $42M to fuel the growth of its fleet of self-driving delivery robots", alt: 'Starship logo', img: 'portfolio/starship-logo.png', link: 'https://techcrunch.com/2022/03/01/starship-technologies-raises-another-42m-to-fuel-the-growth-of-its-fleet-of-self-driving-delivery-robots/'},
          {title: "Gatik CEO sees future of autonomous trucking in short hauls", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://abcnews.go.com/Technology/wireStory/gatik-ceo-sees-future-autonomous-trucking-short-hauls-82721577'},
          {title: "Spiffy adds another $10M to Series B funding round", alt: 'Spiffy logo', img: 'portfolio/spiffy-logo-color.png', link: 'https://www.autoremarketing.com/technology/spiffy-adds-another-10m-series-b-funding-round'},
          {title: "Revel to expand EV fast-charging network with backing from BlackRock", alt: 'Revel logo', img: 'portfolio/revel-logo-color.png', link: 'https://techcrunch.com/2022/02/23/revel-to-expand-ev-fast-charging-network-with-backing-from-blackrock/'},
          {title: "Formant is solving the robotic Tower of Babel with a unified platform", alt: 'Formant logo', img: 'portfolio/formant-logo.png', link: 'https://techcrunch.com/2022/01/05/formant-series-a/'},
          {title: "Announcing Formant&#x27;s Series A — and what it means for us in 2022", alt: 'Formant logo', img: 'portfolio/formant-logo.png', link: 'https://formant.io/news-and-blog/2022/01/05/company-updates/announcing-formants-series-a-and-what-it-means-for-us-2022/ '},
          {title: "Goodyear Develops Airless Tire for Starship's Delivery Robots", alt: 'Starship logo', img: 'portfolio/starship-logo.png', link: 'https://www.pcmag.com/news/goodyear-develops-airless-tire-for-starships-delivery-robots'},
          {title: "AmpUp Launches EV Fleet Charging Solution", alt: 'AmpUp logo', img: 'portfolio/ampup-logo-color.png', link: 'https://www.prnewswire.com/news-releases/ampup-launches-ev-fleet-charging-solution-301451927.html'},
          {title: "In partnership with @JuiceBar AmpUp is helping power EVs for Wynn and Encore guests", alt: 'AmpUp logo', img: 'portfolio/ampup-logo-color.png', link: 'https://www.linkedin.com/posts/ampup-charging_ev-evcharging-evse-activity-6889214543550021632-1SoC'},
          {title: "Gatik's Approach to Structured Autonomy and How This is Helping Customers", alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.linkedin.com/posts/gatik_ai_from-the-very-beginning-at-gatik-the-focus-activity-6851109894528012288-bZNU '},
          {title: 'Goodyear Invests In Gatik And Tests Smart Tires On Automated Trucks', alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.forbes.com/sites/samabuelsamid/2021/10/05/goodyear-invests-in-gatik-and-tests-smart-tires-on-automated-trucks/?sh=13dfb52c27f6'},
          {title: 'Goodyear Invests in Autonomous Trucking Company Gatik & Collaborates to Enhance Safety & Efficiency for Autonomous Middle Mile Logistics', alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://corporate.goodyear.com/us/en/media/news/goodyear-invests-in-autonomous-trucking-company-gatik-and-collaborates-to-enhance-safety-and-efficiency-for-autonomous-middle-mile-logistics.html '},
          {title: 'Ryder, Gatik team up to roll out U.S. autonomous delivery network', alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.reuters.com/technology/ryder-gatik-team-up-roll-out-us-autonomous-delivery-network-2021-10-19/ '},
          {title: 'Gatik Partners with Ryder', alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.linkedin.com/posts/gatik_ai_gatik-partners-with-ryder-ugcPost-6857252382221357057-Cqyx'},
          {title: 'Starship Robots Have Now Completed 2,000,000 Deliveries', alt: 'Starship Technologies logo', img: 'portfolio/starship-logo.png', link: 'https://www.linkedin.com/posts/starshiptechnologies_starship-robots-have-now-completed-2000000-activity-6856293108481622016--Vxy '},
          {title: 'Spiffy Announces $22 Million Series B to Accelerate Nationwide Mobile Car Care', alt: 'Spiffy logo', img: 'portfolio/spiffy-logo-color.png', link: 'https://finance.yahoo.com/finance/news/spiffy-announces-22-million-series-105500350.html '},
          {title: 'Goodyear Ventures, Porsche Ventures invest in virtual sensing company Tactile Mobility', alt: 'Tactile Mobility logo', img: 'portfolio/tactile-mobility-logo.png', link: 'https://techcrunch.com/2021/10/27/goodyear-ventures-porsche-ventures-invest-in-virtual-sensing-company-tactile-mobility/ '},
          {title: 'Autofleet Closes Oversubscribed $20 Million Series B', alt: 'Autofleet logo', img: 'portfolio/autofleet-logo-color.png', link: 'https://www.globenewswire.com/news-release/2021/10/28/2322499/0/en/Autofleet-Closes-Oversubscribed-20-Million-Series-B.html '},
          {title: 'Gatik Joins the Driverless Self-Driving Car Club', alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www.bloomberg.com/news/articles/2021-08-28/gatik-joins-the-driverless-self-driving-car-club'},
          {title: 'Robot Truck Startup Gatik Raises $85 Million In Koch-Led Funding Round', alt: 'Gatik logo', img: 'portfolio/gatik-logo-color.png', link: 'https://www-forbes-com.cdn.ampproject.org/c/s/www.forbes.com/sites/alanohnsman/2021/08/31/robot-truck-startup-gatik-raises-85-million-in-koch-led-funding-round/amp/'},
          {title: 'Goodyear Ventures Invests in EV Charging and Software Company AmpUp', alt: 'AmpUp logo', img: 'portfolio/ampup-logo-color.png', link: 'https://corporate.goodyear.com/us/en/media/news/goodyear-ventures-invests-in-ev-charging-and-software-company-am.html'},
          {title: 'Starship Technologies is bringing food delivery robots to four more US college campuses this year', alt: 'Starship Technologies logo', img: 'portfolio/starship-logo.png', link: 'https://techcrunch.com/2021/08/10/starship-technologies-is-bringing-food-delivery-robots-to-four-more-us-college-campuses-this-year/'},
          {title: 'Former head of Alphabet’s Loon joins Starship Technologies as new CEO', alt: 'Starship Technologies logo', img: 'portfolio/starship-logo.png', link: 'https://techcrunch.com/2021/06/01/former-head-of-alphabets-loon-joins-starship-technologies-as-new-ceo/'},
          {title: 'Goodyear Ventures Invests in Autonomous Delivery Services Provider Starship Technologies', alt: 'Starship Technologies logo', img: 'portfolio/starship-logo.png', link: 'https://corporate.goodyear.com/en-US/media/news/goodyear-ventures-invests-in-autonomous-delivery-services-provider-starship-technologies.html'},
          {title: 'Starship Completes One Million Autonomous Deliveries', alt: 'Starship Technologies logo', img: 'portfolio/starship-logo.png', link: 'https://medium.com/starshiptechnologies/one-million-autonomous-deliveries-milestone-65fe56a41e4c'},
          {title: 'Envoy Secures $81 Million in Financing to Accelerate Adoption of an Electric Vehicle Future', alt: 'Envoy logo', img: 'portfolio/envoy-logo.png', link: 'https://www.envoythere.com/envoy-series-a-funding-annoucement'},
          {title: 'Goodyear To Advance Future Mobility with Launch of New Venture Capital Fund', alt: 'Goodyear Ventures logo', img: 'goodyear-ventures-logo-black.png', link: 'https://corporate.goodyear.com/en-US/media/news/goodyear-to-advance-future-mobility-with-launch-of-new-venture-capital-fund.html'}
        ],
        sets: []
      }
  },
  mounted() {
    this.groupArr(this.newsArticles, 3)
  },
  methods: {
    groupArr(data, n) {
      // group data by number
      var group = [];
      for (var i = 0, j = 0; i < data.length; i++) {
          if (i >= n && i % n === 0)
              j++;
          group[j] = group[j] || [];
          group[j].push(data[i])
      }
      // console.log('group is now: ' + group)
      this.sets = group
      return group;
    },
    scrollOver (whichWay) {
      let scrollBox = document.getElementById('justKeepScrolling');

      if (whichWay.toLowerCase() === 'right') {
        // scroll right
        scrollBox.scrollLeft += 100;
      } else {
        // scroll left
        scrollBox.scrollLeft -= 100;
      }
      
    },
    openArticle (newsLink) {
      window.open(newsLink);

      // console.log("tracking click for : " + newsLink)
      if (newsLink) {
        window.dataLayer.push({ 'event': 'clickedCustom', 'clickDetails': 'News: ' + newsLink })
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subheader {
  margin-bottom: 100px;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 70px;
}
.btn.gy-fill {
  padding: 0.375rem;
  min-width: 125px;
}
.scroll-desc {
  margin-bottom: 0px;
  text-align: center;
}
.gy-scroll {
  overflow: auto;
  border: 1px solid rgba(0,0,0, .10);
  box-shadow: 5px 10px 18px rgb(0 0 0 / 20%);
}
.card {
  box-shadow: 5px 10px 18px rgb(0,0,0, .2);
  -webkit-box-shadow: 5px 10px 18px rgb(0,0,0, .2);
  border: none;
  border-radius: 0px;
  border-left: 3px solid #F15A26;
  /*border-image-source: linear-gradient(180deg, rgb(249, 237, 37), rgb(241, 90, 38));
  border-image-slice: 1;*/
  min-height: 120px;
  min-width: 318px;
}
.card:hover, .card:hover .card-body, .card:hover .card-text, .card:hover .gy-link {
  text-decoration: underline;
  cursor: pointer;
}
.gy-link {
  color: #F15A26;
}
.card-body {
  padding: 1rem;
}
.card-text {
  float: left;
  width: 70%;
}
.card-img-left {
  border-radius: 0px;
  width: 27%;
  float: left;
  margin-right: 3px;
}
.card-footer {
  background-color: transparent;
  border: none;
  padding: 0.75rem 1.25rem 0px;
}
@media (max-width:992px) {
  .subheader::before { 
    display: block; 
    content: " "; 
    margin-top: -254px; 
    height:254px; 
    visibility: hidden; 
    pointer-events: none;
  }
}
</style>
