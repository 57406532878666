<template>
  <section id="portfolio" class="portfolio-section">
      <b-container>
        <b-row class="justify-content-center">
          <b-col xs="12" sm="6" md="4" lg="3" v-for="item in portfolioCompanies" v-bind:key="item.id">
            <a class="gy-cardlink" :href="item.cardLink" v-on:click="trackClick($event)" target="_blank">
            <div class="card">
              <span v-if="item.active" class="active"></span>
              <span v-if="item.exited" class="exited">IPO</span>
              <span v-if="item.acquired" class="acquired">ACQUIRED</span>
              <img class="card-img-top" :src="require(`../../assets/images/portfolio/${item.cardImg}`)" :alt="item.alt" />
              <div class="card-body">
                  <p class="card-text">{{ item.cardText }}</p>
                  <div class="card-footer text-right"><a class="gy-link">Learn More ></a></div>
              </div>
            </div>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </section>
</template>

<script>
window.dataLayer = window.dataLayer || []
export default {
  name: 'Portfolio',
  data () {
    return {
      portfolioCompanies: [
        {id: 0, cardLink: 'https://ampup.io/', active: true, exited: false, acquired: false, cardImg: 'ampup-logo-color.png', alt: 'AmpUp logo', cardText: 'AmpUp is an electric vehicle (EV) software company and network provider that enables drivers, hosts and fleets to charge stress-free.'},
        {id: 1, cardLink: 'https://www.autofleet.io/', active: true, exited: false, acquired: true, cardImg: 'autofleet-logo-color.png', alt: 'Autofleet logo', cardText: 'Autofleet provides the leading Vehicle-as-a-Service platform for fleets to optimize existing operations and to seamlessly launch new business models from existing assets.'},
        {id: 2, cardLink: 'https://www.corractions.com/', active: true, exited: false, acquired: false, cardImg: 'corractions-logo.png', alt: 'CorrActions logo', cardText: 'CorrActions is a neuroscience based, software only, driver monitoring company.'},
        {id: 3, cardLink: 'https://www.envoythere.com/', active: true, exited: false, acquired: true, cardImg: 'envoy-logo.png', alt: 'Envoy logo', cardText: 'Envoy is the leading provider of on-demand shared electric vehicles, offering Mobility as an Amenity for communities, where people live, work and stay.'},
        {id: 4, cardLink: 'https://formant.io/', active: true, exited: false, acquired: false, cardImg: 'formant-logo.png', alt: 'Formant logo', cardText: 'Formant is a robot command center that lets customers deploy faster, scale while reducing overhead and maximize the value of a robot fleet and the data it produces.'},
        {id: 5, cardLink: 'https://gatik.ai/', active: true, exited: false, acquired: false, cardImg: 'gatik-logo-color.png', alt: 'Gatik logo', cardText: 'Gatik is a leader in autonomous middle mile logistics, delivering goods safely and efficiently using its fleet of light and medium duty trucks.'},
        {id: 6, cardLink: 'https://www.helm.ai/', active: true, exited: false, acquired: false, cardImg: 'helm-logo.png', alt: 'Helm logo', cardText: 'Helm.ai is building the next generation of scalable AI technology for autonomous driving and robotics.'},
        {id: 7, cardLink: 'https://nova.xyz/', active: true, exited: false, acquired: false, cardImg: 'nova-helium-logo-color.png', alt: 'Nova Labs and Helium logo', cardText: 'Nova Labs is the founder of the Helium Network, a global, distributed network of hotspots that create public, long-range wireless coverage for Internet of Things (IoT) devices.'},
        {id: 8, cardLink: 'https://ottometric.com/', active: true, exited: false, acquired: false, cardImg: 'ottometric-logo.png', alt: 'Ottometric logo', cardText: 'Ottometric is a software company focused on automating and streamlining the validation and training processes for Advanced Driver Assistance Systems (ADAS).'},
        {id: 9, cardLink: 'http://www.pretred.com', active: true, exited: false, acquired: false, cardImg: 'pretred-logo.png', alt: 'Pretred logo', cardText: 'Pretred develops sustainable, recycled rubber barriers made from waste tires.'},
        {id: 10, cardLink: 'https://www.recurrentauto.com/', active: true, exited: false, acquired: false, cardImg: 'recurrent-logo-color.png', alt: 'Recurrent logo', cardText: 'Recurrent develops battery and range analysis reports for EVs to increase transparency and confidence in pre-owned electric car transactions.'},
        {id: 11, cardLink: 'https://gorevel.com/', active: true, exited: false, acquired: false, cardImg: 'revel-logo-color.png', alt: 'Revel logo', cardText: 'Revel is a Brooklyn-based transportation company that’s electrifying cities through charging infrastructure and shared electric vehicle fleets.'},
        {id: 12, cardLink: 'https://www.getspiffy.com/', active: true, exited: false, acquired: false, cardImg: 'spiffy-logo-color.png', alt: 'Spiffy logo', cardText: 'Spiffy is an on-demand technology and services company with the mission to disrupt the car care experience everywhere.'},
        {id: 13, cardLink: 'https://www.starship.xyz/business/', active: true, exited: false, acquired: false, cardImg: 'starship-logo.png', alt: 'Starship Technologies logo', cardText: 'Starship Technologies is revolutionizing deliveries with autonomous robots that are designed to deliver food, groceries and packages locally in minutes.'},
        {id: 14, cardLink: 'https://tactilemobility.com/', active: true, exited: false, acquired: false, cardImg: 'tactile-mobility-logo.png', alt: 'Tactile Mobility logo', cardText: 'Tactile Mobility leverages existing vehicle sensors and artificial intelligence (AI) to equip vehicles with the missing sense of “touch."'},
        {id: 15, cardLink: 'https://zypp.app/', active: true, exited: false, acquired: false, cardImg: 'zypp-logo.png', alt: 'Zypp logo', cardText: 'Zypp Electric is a last-mile delivery company in India utilizing electric vehicles and EV-based technology to make last-mile logistics sustainable and emission-free.'}
      ]
    }
  },
  methods: {
    trackClick (e) {
      let clickedUrl = e.currentTarget.href;
      console.log("tracking click for : " + clickedUrl)
      if (clickedUrl) {
        window.dataLayer.push({ 'event': 'clickedCustom', 'clickDetails': 'Portfolio/Portfolio: ' + clickedUrl })
      } else {
        console.log("no click url exists")
      }
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subheader {
  margin-bottom: 80px;
  text-align: center;
  font-size: 2.5rem;
  margin-top: 70px;
}
.gy-cardlink {
  display: block;
}
.gy-link {
  color: #F15A26;
}
.card {
  margin-bottom: 20px;
  border-radius: 0px;
  border: 0px;
  box-shadow: none;
  color: #000;
}
.active {
  height: 22px;
  display: block;
}
.exited {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #000;
  background-color: #F9ED25;
  display: block;
  margin-left: 0px;
  margin-top: 0px;
  width: 40px;
  height: 22px;
  padding: 1px;
}
.acquired {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #000;
  background-color: #F9ED25;
  display: block;
  margin-left: 0px;
  margin-top: 0px;
  width: 90px;
  height: 22px;
  padding: 1px;
}
.gy-cardlink:hover, .card:hover {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  
}
.gy-cardlink:hover .card {
  border: 1px solid rgba(0,0,0, .10);
  -webkit-box-shadow: 5px 10px 18px rgb(0 0 0 / 20%);
  box-shadow: 5px 10px 18px rgb(0 0 0 / 20%);
}
.card:hover .gy-link {
  text-decoration: underline;
}
.card img {
  width: 85%;
  margin: 0 auto;
  margin-top: 5px;
}
.card-body {
  padding: 1rem;
}
.card-footer {
  background-color: transparent;
  border: none;
  padding: 0.75rem 1.25rem 0px;
}
.card-footer.long {
  padding-top:15px;
}

@media (min-width:576px) and (max-width:767px) {
  .card {
    height: 470px;
  }
  .card-text {
    height: 200px;
  }
}
@media (max-width: 768px)  {
  .card img {
    width: 70%;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .card {
    height: 440px;
  }
}
@media (min-width:768px) and (max-width:1199px) {
  .card-text {
    height: 250px;
  }
}
@media (min-width:992px) and (max-width:1199px) {
  .card {
    height: 455px;
  }
}
@media (min-width:1200px){
  .card {
    height: 430px;
  }
  .card-text {
    min-height: 145px;
  }
}

</style>
